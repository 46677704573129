import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST,updateloginStatus,setMerchant,setToken,isLoggedIn,isOutletSet, merchantKey, loadSuccess, loadError, dialCodes} from '../../../utils/gaia';
import ToolBar from '../../Components/toolbarNL'
import Buttontb from '../../Components/Button'
import {Images} from '../../../utils/Images'
import commonPassword from 'common-password'


import { Button, Form, FormGroup, Label, Input, FormText,Spinner, ButtonGroup,ButtonDropdown,DropdownToggle, DropdownItem,DropdownMenu, InputGroup } from 'reactstrap';
 
const Login = (props) => {
  const navigate = useNavigate();
  const [fullname, setFullname] = useState("")
  const [mobile, setMobile] = useState("")
  const [email, setEmail] = useState("")
  const [postal, setPostal] = useState("")
  const [dialCode, setDialCode] = useState("65")
  const [password, setPassword] = useState("")
  const [cpassword, setCPassword] = useState("")
  const [gender, setGender] = useState("Select")
  const [day, setDay] = useState("DD")
  const [month, setMonth] = useState("MM")
  const [year, setYear] = useState("YYYY")
  const [loading, setLoading] = useState(false)
  const [genderOpen, setGenderOpen] = useState(false)
  const [dayOpen, setDayOpen] = useState(false)
  const [yearOpen, setYearOpen] = useState(false)
  const [dcOpen, setDcOpen] = useState(false)
  const [monthOpen, setMonthOpen] = useState(false)
  const [tc, setTc] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [registered, setRegistered] = useState(false)

  const genYears = () =>{
    let cap = new Date().getFullYear() - 16;
    let payload = []
    for(let i = cap; i >= 1900; i--){
      payload.push(<DropdownItem onClick={()=>setYear(i)}>{i}</DropdownItem>)
    }
    return payload
  }

  const genMonths = () =>{
    let payload = []
    for(let i = 1; i <= 12; i++){
      payload.push(<DropdownItem onClick={()=>setMonth(i)}>{i}</DropdownItem>)
    }
    return payload
  }

  const genDialCodes = () =>{
    let payload = []
    dialCodes.map((code)=>(
      payload.push(<DropdownItem onClick={()=>setDialCode(code)}>{code}</DropdownItem>)
    ))
    return payload
  }

  const genDays = () =>{
    let payload = []
    for(let i = 1; i <= 31; i++){
      payload.push(<DropdownItem onClick={()=>setDay(i)}>{i}</DropdownItem>)
    }
    return payload
  }

  useEffect(()=>{
    if(isLoggedIn()){
      navigate(`/app`)
    }
  },[])

  const toggle = (type) =>{
    if(type == "gender"){
      setGenderOpen(!genderOpen);
    }else if(type == "d"){
      setDayOpen(!dayOpen);
    }else if(type == "m"){
      setMonthOpen(!monthOpen);
    }else if(type == "y"){
      setYearOpen(!yearOpen);
    }else if(type == "dc"){
      setDcOpen(!dcOpen)
    }
    
  } 

  
  const handleCheckbox = (type) =>{
    if(type == "pp"){
      setPrivacy(!privacy)
    }else{
      setTc(!tc)
    }
    
  }

  const handleSubmit = (evt) =>{
    var that = this;
    evt.preventDefault();
    if(fullname === ""){
      loadError("Please enter your name.")
      return;
    }

    if(mobile === "" || mobile.length < 8){
      loadError("Please enter a valid hand phone number")
      return;
    }

    if(email === ""){
      loadError("Please enter your email address.")
      return;
    }

    if(day === "DD" || month == "MM" || year == "YYYY"){
      loadError("Please enter a valid date.")
      return;
    }

    if(password === "" ||  password.length < 6){
      loadError("Please enter a valid password, (min: 6 characters).")
      return;
    }

    /*if(commonPassword(password)){
      loadError("Password provided is too common. please try another.")
      return;
    }*/

    if(password !== cpassword){
      loadError("Passwords do not match")
      return;
    }

    if(!tc){
      loadError("Please accept our terms and conditions.")
      return;
    }

    if(!privacy){
      loadError("Please accept our privacy policy.")
      return;
    }
    let dob = `${year}-${month}-${day}`
    
    setLoading(true);
    Axios.post(`${APIHOST}registerv3`, {
      email : email,
      name : fullname,
      phone : mobile,
      gender : gender,
      dc : dialCode,
      dob : dob,
      password : password,
      pin : '098765',
      mct : merchantKey,
      type: 1
    }).then(function(res){
        let {data} = res;
        //updateloginStatus(true);
        //setToken(data.access_token);
        //setMerchant(data.details);
        setLoading(false);
        setRegistered(true)
    }).catch(function(er){
      setLoading(false);
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
    })
  }

    return (
      <>
      <div className="container bg">
      <ToolBar title="register" past="/" hasMenu={false} />
      
        <div id="register">
        {!registered ? <button onClick={()=> navigate("/")} className="btn back-btn" ><i className="fa fa-chevron-left"></i></button>: ""}
        <div className="inner">
        {!registered ? <div className="top">
            <h1>Sign up for membership</h1>
            <div className="frm-box">
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="username">Full Name</Label>
                  <Input className="text-field" type="text" id="username" name="username" placeholder='Enter your fullname' onChange={event => setFullname(event.target.value)} required />
                </FormGroup>
                <FormGroup>
                  <Label for="username">Email</Label>
                  <Input className="text-field" type="email" id="email" name="email" placeholder='Enter your Email address' onChange={event => setEmail(event.target.value)} required />
                </FormGroup>
                <FormGroup>
                  <Label for="username">Phone Number</Label>
                  <InputGroup>
                    <ButtonDropdown isOpen={dcOpen} toggle={()=>toggle("dc")}>
                      <DropdownToggle style={{borderRadius : "5px"}} className='tb-form-dd' caret>
                        {dialCode}
                      </DropdownToggle>
                      <DropdownMenu>
                        {genDialCodes()}
                      </DropdownMenu>
                    </ButtonDropdown>
                    <Input className="text-field" type="number" id="mobile" name="mobile" placeholder='Enter your mobile number' onChange={event => setMobile(event.target.value)} required />
                  </InputGroup> 
                </FormGroup>
                <div className='row mb-4'>
                  <div className='col-4'>
                    <Label for="username" className='show' >Gender</Label>
                    <ButtonDropdown isOpen={genderOpen} toggle={()=>toggle("gender")}>
                      <DropdownToggle className='tb-form-dd' caret>
                        {gender}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={()=>setGender("Male")}>Male</DropdownItem>
                        <DropdownItem onClick={()=>setGender("Female")}>Female</DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                  <div className='col-7'>
                  <Label for="username">Birthday</Label>
                  <ButtonGroup>
                    <ButtonDropdown isOpen={dayOpen} toggle={()=>toggle("d")}>
                      <DropdownToggle className='tb-form-dd' caret>
                        {day}
                      </DropdownToggle>
                      <DropdownMenu>
                      {genDays().map((item)=>(item))}
                      </DropdownMenu>
                    </ButtonDropdown>
                    <ButtonDropdown isOpen={monthOpen} toggle={()=>toggle("m")}>
                      <DropdownToggle className='tb-form-dd' caret>
                        {month}
                      </DropdownToggle>
                      <DropdownMenu>
                      {genMonths().map((item)=>(item))}
                      </DropdownMenu>
                    </ButtonDropdown>
                    <ButtonDropdown isOpen={yearOpen} toggle={()=>toggle("y")}>
                      <DropdownToggle className='tb-form-dd' caret>
                        {year}
                      </DropdownToggle>
                      <DropdownMenu>
                        {genYears().map((item)=>(item))}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </ButtonGroup>
                  </div>
                </div>
                <FormGroup  className="mb-4">
                  <Label for="username">Password</Label>
                  <Input className="text-field" type="password" id="password" name="password" placeholder='Enter your Password' onChange={event => setPassword(event.target.value)} required />
                </FormGroup>
                <FormGroup  className="mb-4">
                  <Label for="username">Confirm Password</Label>
                  <Input className="text-field" type="password" id="cpassword" name="cpassword" placeholder='Confirm your Password' onChange={event => setCPassword(event.target.value)} required />
                </FormGroup>
                <FormGroup className='mb-4'>
                  <Label className='cbox' for='tc'>
                    <input type="checkbox" id='tc' value="tc" onChange={()=>{handleCheckbox("tc")}} />
                    <span> I agree to the <a href='/terms' target="_blank">Terms and Conditions.</a></span> 
                  </Label>
                  <Label  className='cbox' for='pp'>
                    <input type="checkbox" id='pp' value="pp" onChange={()=>{handleCheckbox("pp")}} />
                    <span> I agree to the <a href='/privacy' target="_blank">Privacy Policy.</a></span> 
                  </Label>
                </FormGroup>
                <Buttontb loader={loading} text={"REGISTER"} classList={"tmpl-main-btn filled"} />
              </Form>
            </div>
          </div>:
          <div className="top">
            <img className='reg-success-img' src={Images.ok} />
            <p className='reg-success-txt'>Congratulations! Your account has been created.</p>
            <a href='/login' className='tmpl-main-btn filled reg-success-btn'>LOGIN</a>
          </div>}
        </div>
      </div>
    </div>
    </>
    );
}

export default Login;
